/*
VUEX
*/

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({

	state: {				// "state" in vuex = "data" in vue
		user: null,			// that's the user currently saved in my state (like login_user in earlier projects)
		season: null		// the season currently displayed
	},

	mutations: {	// "mutations" are used to commit and track state changes
		// after successful login:
		SET_USER_DATA(state, userData) {
			// 1. write the logged in user to our state!
			state.user = userData
			state.season = state.user.seasons[0]
			// 2. write it (jwttoken, username, firstname, lastname) to the localStorage of the Browser!
			localStorage.setItem('user', JSON.stringify(userData)) // stringify, weil der LocalStorage ein String erwartet, nicht ein JSON-Object
			// 3. add the token to the header of all future axios calls (Axios Globals)
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + userData.jwttoken;
		},

		UPDATE_USER_DATA(state, userData) {
			userData.forEach(() => {
				Object.entries(state.user).forEach(([attr, value]) => {
					state.user[attr] = value
				})
			});
		},

		// after logout:
		CLEAR_USER_DATA() {
			// 1. remove the user from the localStorage
			localStorage.removeItem('user')
			// 2. clear the axios header and clear the user state just by reloading
			location.reload()
			// 3. clear the axios defaults
			axios.defaults.headers.common['Authorization'] = ''
		},

		SET_ACTIVE_SEASON(state, season) {
			state.season = season
		},

		ADD_USER_SEASON(state, season) {
			state.user.seasons.push(season);
			state.season = season
		}


	},

	actions: {	// "actions" in vuex = "methods" in vue; actions should call mutations, which update state

		// registering
		register({ commit }, credentials) {	// these credentials coming from the method "register" in RegisterUser.vue
			// send this to API
			return axios.post(process.env.VUE_APP_API_URL + 'user', credentials)
				.then(response => {
					console.log('Erfolgreiche Registrierungsantwort:', response)
					commit('SET_USER_DATA', response.data)	// puts this data coming back to the mutation
					return { data: response.data, status: response.status}
				})
				.catch(error => {
					return { data: error.response.data, status: error.response.status}
				})
		},

		// when a user logs in
		login({ commit }, credentials) {	// these credentials coming from "login" method in LoginUser.vue
			return axios.post(process.env.VUE_APP_API_URL + 'login', credentials)
				.then(
					({ data }) => {
						commit('SET_USER_DATA', data)	// sends this data coming back from API on to the mutation above
					}
				)
		},

		// when logout button in NavBar is clicked:
		logout({ commit }) {
			commit('CLEAR_USER_DATA') // see mutation above
		},

		setSeason({ commit }, season) {
			commit('SET_ACTIVE_SEASON', season)
		},

		addUserSeason({ commit }, season) {
			commit('ADD_USER_SEASON', season);
		}

	},

	getters: {	// "getters" in vuex = "computed" in vue
		// used like this: v-if="loggedIn" => is only shown when a User is logged in
		loggedIn(state) {
			return !!state.user	// "!!" returns the thruthiness or falsiness of the value instead of the value itself
		},
		isPlayer(state) {
			if (!state.user) return false
			return state.user.status > 0
		},
		isAdmin(state) {
			if (!state.user) return false
			return state.user.status > 1
		},
	},

})