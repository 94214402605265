<!--
View: LoginUser
===============

Description
***********

This is where all users can Authenticate.
I wonder if everything is ok here.

I have to contribute to
************************
<a href="https://www.freepik.com/free-photos-vectors/travel">Travel vector created by freepik - www.freepik.com</a>



-->
<template>
  <div>

    <!-- Login -->
    <b-card
      :img-src="require('@/assets/titleimg.jpg')"
      img-alt="Image"
      img-top
      class="login_card"
    >
      <b-form @submit.prevent="login">

        <!-- E-Mail -->
        <b-form-group>
          <label for="email">E-Mail</label>
          <b-form-input
            v-model="email"
            :state="valid_email ? true : (buttonPressed ? false : null)"
            id="email"
            name="email"
            type="email"
          ></b-form-input>
          <b-form-invalid-feedback>
            Bitte geben Sie eine gütltige E-Mail-Adresse ein.
          </b-form-invalid-feedback>
        </b-form-group>


        <!-- password -->
        <b-form-group>
          <label for="password"> Passwort</label>
          <b-form-input
            v-model="password"
            id="password"
            name="password"
            type="password"
          ></b-form-input>
        </b-form-group>

        <!-- to submit on enter -->
        <input type="submit" hidden />

        <!-- Login button (empty all old errors from box for error messages when clicked) -->
        <b-button class="mt-2 mb-4" variant="success" @click="login()">Login</b-button>

        <!-- register link -->
        <p class="mb-0">
          <small>Musst du dich zuerst <span class="link" @click="$router.push({name: 'register'})">registrieren?</span></small>
        </p>

        <!-- Password-Link -->
        <p class="mb-0">
          <small><span class="link" @click="$router.push({name: 'forgotPassword'})">Passwort vergessen?</span></small>
        </p>

        <hr />

        <small>pyramix für deinen Tennisclub?<br /><a href="https://kreativnetz.ch/kontakt.php" target="_blank">Setze dich mit mir in Verbindung</a>.</small>

      </b-form>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'LoginUser',
  components: {
  },
  data () {
    return {
      email: '',        // username entered by the user
      password: '',     // password entered by the user
      buttonPressed: false
    }
  },
  computed: {
    valid_email() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.exec(this.email)
    }
  },
  created() {
  },
  methods: {
    // on form submit => login method
    login() {
      this.errors = []
      this.buttonPressed = true
      // set all the fields to "have been touched"
      this.$store.dispatch('login', {       // calls VUEX-Action "login" (store.js)
        email: this.email,
        password: this.password
      })
      .then( () => {
        // redirect to dashboard if successful
        this.$router.push({ name: 'dashboard' })
      })
        .catch(() => { 
          this.$toast.error('Login fehlgeschlagen.')
        })
    },
  }
}
</script>

<style lang="css" scoped>

/* LOGO */

.title_image_div {
  text-align: center; /* centering the logo */
}

.title_image {
  width: 15rem; /* sizing the logo */
  margin: 0 auto; /* position of the logo */
}

/* FORM */

.login_card {
  max-width: 20rem; /* slim box */
  margin: 0 auto;
}

input {
  height: 2.6em;
  padding: 0.5em;
}

label {
  margin-bottom: 0;
  font-size: 80%;
}

.validated_input {
  position: relative;
  font-weight: 300;
  color: #666;
}

.errors {
  border-radius: 0.25em;
  border: 1px #dc3545 solid;
  background-color: #F7CABD;
  margin: 2em 0;
  padding: 0.5em 2em;
  font-size: 80%;
}

.errors li {
  list-style-type: none;
  margin: 0.5em 0;
}

.link {
  color: #117eae;
  text-decoration: underline;
  cursor: pointer;
}


</style>