<!--
View: RegisterUser
==================

-->
<template>
  <div>

    <b-card
    :img-src="require('@/assets/titleimg.jpg')"
        img-alt="Image"
      img-top
      class="register_card"
    >
      <b-form @submit.stop.prevent novalidate>

        <!-- Zugangscode -->
        <b-form-group>
          <label for="accesscode">
            Zugangscode
            <Icon icon="circle-question" title="Zugangscode zu deiner Pyramide" />
          </label>
          <b-form-input
            v-model="accesscode"
            :state="this.accesscode.length == 8 ? true : (buttonPressed ? false : null)"
            id="accesscode"
            name="accesscode"
            type="text"
          ></b-form-input>
        </b-form-group>

        <!-- Vorname -->
        <b-form-group>
          <label for="firstname">Vorname</label>
          <b-form-input
            v-model="firstname"
            :state="this.firstname.length > 1 ? true : (buttonPressed ? false : null)"
            id="firstname"
            name="firstname"
            type="text"
          ></b-form-input>
        </b-form-group>

        <!-- Nachname -->
        <b-form-group>
          <label for="firstname">Nachname</label>
          <b-form-input
            v-model="lastname"
            :state="this.lastname.length > 1 ? true : (buttonPressed ? false : null)"
            id="lastname"
            name="lastname"
            type="text"
          ></b-form-input>
        </b-form-group>

        <label for="sex">Geschlecht</label>
        <b-form-group>
          <b-form-radio-group name="sex" v-model="sex" buttons button-variant="light" size="sm" class="w-100">
            <b-form-radio value="1">&nbsp;<Icon icon="mars" size="m" /></b-form-radio>
            <b-form-radio value="2">&nbsp;<Icon icon="venus" size="m" /></b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <!-- E-Mail -->
        <b-form-group>
          <label for="email">E-Mail</label>
          <b-form-input
            v-model="email"
            :state="valid_email ? true : (buttonPressed ? false : null)"
            id="email"
            name="email"
            type="email"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <label for="password">
            Passwort
            <Icon icon="circle-question" title="Lege hier dein Passwort für pyramix.ch fest." />
          </label>
          <b-form-input
            v-model="password"
            id="password"
            name="password"
            type="password"
          ></b-form-input>
          <small class="form-text text-muted"></small>
        </b-form-group>

        <b-button class="mt-2 mb-4" variant="success" @click="register()">registrieren</b-button>

        <p class="mb-0">
          <small>Du hast schon einen Account? <span class="link" @click="$router.push({name: 'login'})">Login</span></small>
        </p>

      </b-form>
    </b-card>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Icon from '../components/Icon.vue'
export default {
  name: 'RegisterUser',
  components: {
    Icon
  },
  data () {
    return {
      accesscode: '',
      firstname: '',
      lastname: '',
      sex: '',
      lang: 'de',
      email: '',
      password: '',
      buttonPressed: false
    }
  },
  computed: {
    ...mapGetters({
    }),
    valid_email() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.exec(this.email)
    }
  },
  methods: {
    // dispatch a vuex action, also called "register" (in store.js),
    // we send the credentials entered in the form above
    register() {
      this.buttonPressed = true
      this.$store.dispatch('register', {
        accesscode: this.accesscode,
        firstname: this.firstname,
        lastname: this.lastname,
        sex: this.sex,
        lang: this.lang,
        // status: this.status,
        email: this.email,
        password: this.password
      })
      .then(response => {
        if (response.status == 200) {
          // redirect him to the dashboard
          this.$router.push({ name: 'dashboard' })
        } else {
          this.$toast.error(response.data, { duration: 5000 });
        }
      })
    },
    // check involved fields of the form
    getStateUsername(field) {
      if (!field.required) return null
      if (!field.email) return false
      if (!field.$invalid) return true
      return null
    },
  }
}
</script>

<style lang="css" scoped>

.title_image_div {
  text-align: center; /* centering the logo */
}

.title_image {
  width: 15rem; /* sizing the logo */
  margin: 0 auto; /* position of the logo */
}
.register_card {
  max-width: 20rem; /* slim box */
  margin: 0 auto;
}


input {
  height: 2.6em;
  padding: 0.5em;
}

label {
  margin-bottom: 0;
  font-size: 80%;
}

.input_icon {
  position: absolute;
  right: 10px;
  top: 5px;
}

.errors {
  border-radius: 1em;
  border: 1px #FF8783 solid;
  margin: 2em 0;
  padding: 0.5em 2em;
  font-size: 90%;
  background-color: #F7CABD;
}

.errors li {
  list-style-type: none;
  margin: 0.5em 0;
}

.link {
  color: #117eae;
  text-decoration: underline;
  cursor: pointer;
}
</style>