<!--
UserConfig
==========

Description
-----------

Displays a Modal (Dialog Box) to config a User profile.
accessible from all other components! :-)

Teachers can config their Students (Names, Avatar, email..)
Users can config themselves (Avatar, email..)

Props
-----

userID      with this ID the User gets loaded by axios:


Functionality
-------------

1. getUser uses axios to get the User with its Avatar
2. User can customize parts of his Avatar
3. User can save his Avatar


API-Request: GET user/self
--------------------------

user = {
  id (Number),
  firstname (String),
  lastname (String),
  sex (Number),
  avatar {
    user 1,
    circle 2,
    skin 1,
    haircut 5,
    haircolor 1,
    shirt 13,
    beard 2,
    extra 0
  }
}

-->
<template>

  <b-modal
    id="user_config"
    ref="userConfig"
    :title="showDataProtection ? 'Datenschutzerklärung' : 'Profil bearbeiten'"
    @ok="handleOk"
    @show="getUser"
    @hidden="closeModal"
    @close="closeModal"
    centered
    hide-footer
  >
    <b-spinner v-if="!user" type="border" class="ml-1" small></b-spinner>

    <form ref="form" v-if="user && !showDataProtection" @submit.stop.prevent="handleSubmit">

        <b-row no-gutters>

          <b-col cols="8" class="p-3">
          <Avatar
          style="display:inline-flex;"
              v-if="user.sex"
              class="avatar"
              :hideextras="true"
              :user="user"
              :size="180"
            />

            <div class="mb-1">
              <p class="mb-0"><strong>{{ user.firstname + ' ' + user.lastname }}</strong> <Icon v-if="this.$store.state.user.status > 1" class="ml-1 pointer" icon="pencil" color="light" size="xxs" @click.native="editingName = !editingName"/></p>
              <b-form-group class="mb-2" v-if="this.$store.state.user.status > 1 && editingName">
                  <b-input id="firstname" name="firstname" v-model="user.firstname" size="sm" type="text" class="mr-sm-2 mb-sm-0"></b-input>
              </b-form-group>
              <b-form-group class="mb-2" v-if="this.$store.state.user.status > 1 && editingName">
                <b-input id="lastname" name="lastname" v-model="user.lastname" size="sm" type="text" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
              </b-form-group>
              <p class="mb-0">{{ user.email }} <Icon class="ml-1 pointer" icon="pencil" size="xxs" color="light" @click.native="editingEmail = !editingEmail"/></p>
              <b-input v-if="editingEmail" id="email" name="email" v-model="user.email" size="sm" type="email" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
            </div>


            <b-form-group class="mb-2" v-if="1==2">
              <b-form-radio-group name="sex" type="number" v-model="user.sex" buttons button-variant="outline-primary">
                <b-form-radio :value="1"><Icon icon="mars"  size="m" /></b-form-radio>
                <b-form-radio :value="2"><Icon icon="venus"  size="m" /></b-form-radio>
                <b-form-radio :value="3"><Icon icon="transgender"  size="m" /></b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-button
              v-if="!token && isAdmin && user.id !== $store.state.user.id"
              class="mt-2"
              size="sm"
              @click="showToken()"
            >
              <b-spinner v-if="tokenSpinner" type="border" class="ml-1" small></b-spinner>
              <span v-else>Passwort anzeigen</span>
            </b-button>

            <div class="mt-1" v-if="token">Passwort: {{ token }}</div>

            <p
              class="dataprotection mt-2"
              @click="showDataProtection = true"
            >
              Datenschutzerklärung
            </p>
          </b-col>

          <b-col cols="3" class="pt-3 text-center">
            <b-button-group class="button_group mb-1 mr-2">
              <b-button @click="changeAvatar('circle', 'down')">&lsaquo;</b-button>
              <Icon icon="circle" class="avatar_icon mx-1 mt-2" title="Hintergrund" />
              <b-button @click="changeAvatar('circle', 'up')">&rsaquo;</b-button>
            </b-button-group><br />
            <b-button-group class="button_group mb-1 mr-2">
              <b-button @click="changeAvatar('haircut', 'down')">&lsaquo;</b-button>
              <Icon icon="scissors" class="avatar_icon mx-1 mt-2" title="Haarschnitt" />
              <b-button @click="changeAvatar('haircut', 'up')">&rsaquo;</b-button>
            </b-button-group><br />
            <b-button-group class="button_group mb-1 mr-2">
              <b-button @click="changeAvatar('haircolor', 'down')">&lsaquo;</b-button>
              <Icon icon="paintbrush" class="avatar_icon mx-1 mt-2" title="Haarfarbe" />
              <b-button @click="changeAvatar('haircolor', 'up')">&rsaquo;</b-button>
            </b-button-group><br />
            <b-button-group class="button_group mb-1 mr-2">
              <b-button @click="changeAvatar('beard', 'down')">&lsaquo;</b-button>
              <Icon icon="ruler-horizontal" class="avatar_icon mx-1 mt-2" title="Bart" />
              <b-button @click="changeAvatar('beard', 'up')">&rsaquo;</b-button>
            </b-button-group><br />
            <b-button-group class="button_group mb-1 mr-2">
              <b-button @click="changeAvatar('skin', 'down')">&lsaquo;</b-button>
              <Icon icon="user" class="avatar_icon mx-1 mt-2" title="Hautfarbe" />
              <b-button @click="changeAvatar('skin', 'up')">&rsaquo;</b-button>
            </b-button-group><br />
            <b-button-group class="button_group mb-1 mr-2">
              <b-button @click="changeAvatar('shirt', 'down')">&lsaquo;</b-button>
              <Icon icon="shirt" class="avatar_icon mx-1 mt-2" title="Kleidung" />
              <b-button @click="changeAvatar('shirt', 'up')">&rsaquo;</b-button>
            </b-button-group><br />
            <b-button-group class="button_group mb-1 mr-2">
              <b-button @click="changeAvatar('extra', 'down')">&lsaquo;</b-button>
              <Icon icon="glasses" class="avatar_icon mx-1 mt-2" title="Accessoires" />
              <b-button @click="changeAvatar('extra', 'up')">&rsaquo;</b-button>
            </b-button-group>
          </b-col>


        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-button class="mt-2" variant="dark" @click="$refs['userConfig'].hide()">abbrechen</b-button>&nbsp;
            <b-button class="mt-2 ml-2" variant="primary" @click="handleSubmit">speichern</b-button>
          </b-col>
        </b-row>


    </form>

    <div v-if="showDataProtection">
      <b-row>
        <b-col class="dataprotection_div">

          <h6>1. Grundsatz</h6>
          <p>Der Schutz Ihrer Privatsphäre ist PYRAMIX ein wichtiges
          Anliegen. Hier wird Ihnen erläutert, welche personenbezogenen
          Daten von Ihnen bearbeitet werden und wie mit diesen umgegangen wird.</p>

          <p>Ihre Personendaten werde sorgfältig erhoben und bearbeitet
          und nur zu den in dieser Datenschutzerklärung beschriebenen
          Zwecken und nur im dafür notwendigen Umfang und im Rahmen
          der anwendbaren Gesetzesvorschriften verwendet.
          Personendaten werden nur soweit und solange aufbewahrt, als
          diese zur Nutzung der Lernplattform erforderlich sind oder
          dies gesetzlich vorgesehen ist.</p>

          <h6>2. Gespeicherte Personendaten</h6>

          <p>Es werden Ihr Name, das von Ihnen
          angegebene Geschlecht und Ihre E-Mail-Adresse gespeichert.</p>

          <p>Weder Ihre IP-Adresse noch Ihr Browserverhalten werden
            gespeichert. Es werden keine Daten an Dritte weitergegeben.
            In Zusammenarbeit mit dem Hosting-Provider wird alles daran
            gesetzt, die Datenbanken vor fremden Zugriffen, Verlusten,
            Missbrauch oder Fälschung zu schützen.</p>


          <h6>3. Rechte der betroffenen Person</h6>

          <p>Sie haben das Recht, jederzeit Auskunft über Ihre bei PYRAMIX gespeicherten
            personenbezogenen Daten zu erhalten. Ebenso haben Sie das Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum
          angegebenen Adresse an PYRAMIX wenden.</p>

          <h6>4.  Datensicherheit</h6>
          <p>PYRAMIX ist bemüht, personenbezogene Daten durch Ergreifung aller technischen
            und organisatorischen Möglichkeiten so zu speichern, dass sie für Dritte
          nicht zugänglich sind.</p>

          <h6>5.  Änderungen dieser Datenschutzerklärung</h6>
          <p>PYRAMIX behält sich das Recht vor, die Datenschutzrichtlinien zu ändern,
          sollten sich die gesetzlichen Bestimmungen oder die Funktionalität von PYRAMIX ändern.
          Nutzer von PYRAMIX werden über solche Änderungen informiert.
          </p>

          <h6>7. Zustimmung</h6>

          <p>Mit der Nutzung von PYRAMIX stimmen Sie dieser Datenschutzerklärung zu.</p>

          <p><small>Stand April 2023</small></p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button class="mt-2" variant="dark" @click="showDataProtection = false">zurück</b-button>
        </b-col>
      </b-row>

    </div>
  </b-modal>

</template>

<script>
// import VUEX getters
import { authComputed } from '../vuex/helpers.js'

import Icon from '../components/Icon.vue'
import Avatar from '../components/Avatar.vue'
import axios from 'axios'

export default {
  name: 'UserConfig',
  components: {
    Icon,
    Avatar
  },
  props: {
    userID: {
      required: true
    }
  },
  data: () => ({
    user: false,
    tokenSpinner: false,
    token: false,
    showDataProtection: false,
    initDarkMode: null,
    editingName: false,
    editingEmail: false,
    // avatar options
    extra: [{ 1: [0, 1, 2, 3, 4] }, { 2: [0, 1, 2, 3, 4] }],
    // 1 = male, 2 = female
    // not all the hair/shirts/extras are available to all sexes
    options: {
      'circle_1': [1, 2, 3, 4, 5, 6, 7],
      'circle_2': [1, 2, 3, 4, 5, 6, 7],
      'circle_3': [1, 2, 3, 4, 5, 6, 7],
      'haircut_1': [0, 1, 2, 4, 5, 9, 10, 11, 13, 14],
      'haircut_2': [0, 1, 2, 3, 4, 5, 6, 7, 8, 10],
      'haircut_3': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14],
      'beard_1': [0, 1, 2],
      'beard_2': [0],
      'beard_3': [0, 1, 2],
      'haircolor_1': [1, 2, 3, 4, 5, 6],
      'haircolor_2': [1, 2, 3, 4, 5, 6],
      'haircolor_3': [1, 2, 3, 4, 5, 6],
      'skin_1': [1, 2, 3, 4],
      'skin_2': [1, 2, 3, 4],
      'skin_3': [1, 2, 3, 4],
      'shirt_1': [1, 2, 4, 5, 6, 9, 10, 11, 12, 13],
      'shirt_2': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      'shirt_3': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      'extra_1': [0, 2, 4],
      'extra_2': [0, 1, 2, 3, 4],
      'extra_3': [0, 1, 2, 3, 4, 5],
    }

  }),
  computed: {
    ...authComputed
  },
  mounted() { 
  },
  methods: {
    getUser() {
      // hide any token loaded earlier
      this.token = false
      // get the User that is logged in
      axios.get(process.env.VUE_APP_API_URL + 'user/' + this.userID)
      .then(response => this.user = response.data)
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // save User and Avatar
      axios.put(process.env.VUE_APP_API_URL + 'user', { user: this.user })
      .then(() => {
        // close the modal
        this.$emit('userConfigDone', this.user)
				this.$toast.success('Änderungen gespeichert.', { timeout: 2000 });
        this.user = false
      })

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['userConfig'].hide()
      })
    },
    changeAvatar(attr, direction) {
      do {
        if (direction == 'up') this.user.avatar[attr]++;
        if (direction == 'down') this.user.avatar[attr]--;
        if (this.user.avatar[attr] > Math.max.apply(null, this.options[attr + '_' + this.user.sex])) this.user.avatar[attr] = Math.min.apply(null, this.options[attr + '_' + this.user.sex])
        if (this.user.avatar[attr] < Math.min.apply(null, this.options[attr + '_' + this.user.sex])) this.user.avatar[attr] = Math.max.apply(null, this.options[attr + '_' + this.user.sex])
      } while (!this.options[attr + '_' + this.user.sex].includes(this.user.avatar[attr]))
    },
    closeModal() {
      this.$emit('userConfigDone', this.user)
      this.user = false
    },
    showToken() {
      this.tokenSpinner = true
      axios.get(process.env.VUE_APP_API_URL + 'user/' + this.userID + '/token')
      .then(response => {
        this.token = response.data
        this.tokenSpinner = false
      })
      .catch(() => {
        this.tokenSpinner = false
      })
    },

  }
}
</script>

<style scoped>
  .dataprotection {
    font-size: 0.9em;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
  .dataprotection_div {
    height: 22em;
    overflow: scroll;
    font-size: 0.9em;
  }
  .avatar_icon {
    min-width: 1.5em;
    text-align: center;
  }
</style>