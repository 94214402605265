var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon_span",class:{
		hoverable: _vm.hovercolor,
		nothoverable: !_vm.hovercolor,
		mytooltip: _vm.title && _vm.title.length > 0,
		toolpos_left: _vm.title && _vm.title.length > 0 && _vm.toolpos == 'left',
		toolpos_top: _vm.title && _vm.title.length > 0 && _vm.toolpos == 'top',
		toolpos_right: _vm.title && _vm.title.length > 0 && _vm.toolpos == 'right',
		toolpos_bottom: _vm.title && _vm.title.length > 0 && _vm.toolpos == 'bottom'
	},style:(_vm.styleObject),attrs:{"data-text":_vm.title}},[(_vm.iconBefore)?_c('span',{staticClass:"icon_before text-right mr-1",style:(_vm.styleObject)},[_vm._v(_vm._s(_vm.iconBefore))]):_vm._e(),_c('i',{class:['icon', 'pointer', _vm.styles[_vm.iconStyle], 'fa-' + _vm.icon]}),(_vm.iconAfter)?_c('span',{staticClass:"icon_after ml-2",style:(_vm.styleObject)},[_vm._v(_vm._s(_vm.iconAfter))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }