<!--
Icon
====

Simply displays an Icon in a given color
Displays a title too

-->
<template>
	<span
		class="icon_span"
		:class="{
			hoverable: hovercolor,
			nothoverable: !hovercolor,
			mytooltip: title && title.length > 0,
			toolpos_left: title && title.length > 0 && toolpos == 'left',
			toolpos_top: title && title.length > 0 && toolpos == 'top',
			toolpos_right: title && title.length > 0 && toolpos == 'right',
			toolpos_bottom: title && title.length > 0 && toolpos == 'bottom'
		}"
		:style="styleObject"
		:data-text="title"
	>
		<span
			v-if="iconBefore"
			:style="styleObject"
			class="icon_before text-right mr-1"
		>{{ iconBefore }}</span>
		<i
			:class="['icon', 'pointer', styles[iconStyle], 'fa-' + icon]"
		></i>
		<span
			v-if="iconAfter"
			:style="styleObject"
			class="icon_after ml-2"
		>{{ iconAfter }}</span>
	</span>
</template>

<script>

export default {
	name: 'MyIcon',
	data () {
		return {
			colors: {
				'blue' : '#007bff',
				'indigo' : '#6610f2',
				'purple' : '#6f42c1',
				'pink' : '#e83e8c',
				'red' : '#dc3545',
				'orange' : '#fd7e14',
				'yellow' : '#ffc107',
				'green' : '#28a745',
				'teal' : '#20c997',
				'cyan' : '#17a2b8',
				'white' : '#fff',
				'gray' : '#6c757d',
				'gray-dark': '#343a40',
				'primary' : '#007bff',
				'secondary' : '#6c757d',
				'success' : '#28a745',
				'info' : '#17a2b8',
				'warning' : '#ffc107',
				'danger' : '#dc3545',
				'light' : '#d8d9da',
				'dark' : '#343a40',
				'verylight' : '#efefef'
			},
			sizes: {
				'xxxxs' : '50%',
				'xxxs'  : '60%',
				'xxs'   : '70%',
				'xs'    : '80%',
				's'     : '90%',
				'm'     : '100%',
				'l'     : '120%',
				'xl'    : '140%',
				'xxl'   : '160%',
				'xxxl'  : '180%',
				'xxxxl' : '200%'
			},
			styles: {
				'regular' : 'fa-regular',
				'solid' : 'fa-solid',
				'light': 'fa-light',
				'duotone': 'fa-duotone',
				'thin': 'fa-thin',
			}
		}
	},
	props: {
		icon: { type: String, required: true },
		color: { type: String, required: false, default: null },
		hovercolor: { required: false, default: false },
		size: { required: false, default: 'm' },
		title: { type: String, required: false },
		toolpos: { type: String, required: false, default: 'bottom' },			// position of mytooltip
		incircle: { required: false, default: false },
		clickable: { required: false, default: false },
		iconBefore: { required: false, default: false },
		iconAfter: { required: false, default: false },
		iconStyle: { required: false, default: 'solid' },
		rotate: { required: false, default: 0 },
	},
	computed: {
		styleObject: function() {
			return {
				'--hovercolor': this.hovercolor ? this.colors[this.hovercolor] : 'none',
				'--color': this.colors[this.iconColor] ? this.colors[this.iconColor] : this.iconColor,
				'fontSize': this.sizes[this.size],
				'color': this.colors[this.iconColor],
			}
		},
		iconColor() {
			if (this.color !== null) return this.color
			return this.darkMode ? 'white' : '#333'
		}

	},
}
</script>

<style lang="css" scoped>
	.icon_span {
		z-index: 1;
		position: relative;
	}
	.icon {
		color: var(--color);
		display: inline !important;
	}
	.icon_span:hover {
		z-index: 2
	}
	.hoverable:hover .icon {
		color: var(--hovercolor);
	}
	.incircle {
		background-color: rgba(0,0,0,0.1);
		padding: 0.25em;
		border-radius: 1em;
		width: 1.5em;
		height: 1.5em;
		transition: all 0.5s;
	}
	.incircle:hover {
		background-color: rgba(0,0,0,0.25);
	}
	.clickable {
		cursor: pointer;
	}
	.icon_before, .icon_after {
		font-weight: 500;
		font-size: 11px !important;
		z-index: 3
	}
	.icon_after {
		display: inline;
		font-weight: 300;
	}
	.mytooltip {
		border: none;
	}
	.mytooltip:before {
		font-size: 0.8em;
		width: auto;
		min-width: auto;
		padding: 0.5em 1em;
	}
	.pointer {
		cursor: pointer;
	}
</style>