<!--
Avatar
======

Displays the User's Avatar
<a href="https://www.freepik.com/free-vector/hand-drawn-people-avatar-collection_5183186.htm#query=avatar&position=15&from_view=author">Image by pikisuperstar</a> on Freepik
-->
<template>
		<div class="avatar" :style="{ width: size + 'px', height: size + 'px', 'cursor': isAdmin || isMyAvatar ? 'pointer' : 'default' }">
			<img :class="{active: active}" :src="getAvatarURL('circle_' + user.avatar.circle + '.svg')" style="opacity: 0.5;" />
			<img :src="getAvatarURL('skin_' + user.sex + '_' + user.avatar.skin +'.svg')" class="gradient" />
			<img :src="getAvatarURL('shirt_' + user.avatar.shirt + '.svg')" >
			<img v-if="user.avatar.beard" :src="getAvatarURL('beard_' + user.avatar.beard + '_' + user.avatar.haircolor + '.svg')" style="opacity: 0.8">
			<img v-if="user.avatar.haircut" :src="getAvatarURL('hair_' + user.avatar.haircut + '_' + user.avatar.haircolor + '.svg')" >
			<img v-if="user.avatar.extra" :src="getAvatarURL('extra_' + user.avatar.extra + '.svg')" >
			<div v-if="!hideextras" class="name text-center" :style="{'font-size': (size/105) + 'em', 'font-weight': isMyAvatar ? (isAdmin ? 300 : 700) : 300}">{{ user.firstname + ' ' + user.lastname[0] + '. '}}</div>
			<div v-if="!hideextras" class="points text-center" :style="{'font-size': (size/100) + 'em'}"><small>{{ user.points }}</small></div>
		</div>
</template>

<script>
// import VUEX getters
import { authComputed } from '../vuex/helpers.js'

// Subcomponents

export default {
	name: 'UserAvatar',
	components: {
	},
	computed: {
		...authComputed,
		isMyAvatar() {
			if (!this.loggedIn) return false
			return this.user.id == this.$store.state.user.id
		}
	},
	mounted() {
		//console.log(this.user)
	},
	props: {
		user: { type: Object, required: true },
		size: { type: Number, required: false, default: 20 },
		showGraduation: { type: Boolean, required: false, default: false },
		active: { type: Boolean, required: false, default: false },
		hideextras: { type: Boolean, required: false, default: false },
	},
	methods: {
		getAvatarURL(imagename) {
			return 'avatars/' + imagename
		}
	}

}
</script>

<style lang="css" scoped>

.avatar {
	position: relative;
	z-index: 1;
	margin: 0 auto;
	display: flex;
	justify-content: center;
  align-items: center;
}

.avatar:hover {
	z-index: 2
}

.avatar img {
	position: absolute;
	width: 80%;
	height: 80%;
	top: 0;
}

.name {
	position: absolute;
	display: inline;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 200%;
	text-align: center;
	white-space: nowrap;
}

.points {
	position: absolute;
	right: -0em;
	bottom: 1.2em;
	font-weight: 700;
	padding: 0 0 0.2em;
	width: 1.6em;
	height: 1.6em;
	background: rgba(255,255,255,0.6);
	border-radius: 100%;
	border: 1px #eee solid;
}

.active {
	border: 4px red solid;
	border-radius: 50%;
}
</style>