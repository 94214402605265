import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

// VueRouter
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// Toast
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, { position: 'top', queue: true, duration: 1000 });

// Vuex
import store from './vuex/store'
import axios from 'axios'

import Dashboard from './views/Dashboard.vue'
import LoginUser from './views/LoginUser.vue'
import ForgotPassword from './views/ForgotPassword.vue'
import RegisterUser from './views/RegisterUser.vue'

// instantiate the VueRouter
const router = new VueRouter({
	//mode: "history",
	routes: [
		{
			path: '/dashboard',
			name: 'dashboard',
			component: Dashboard,
			meta: { requiresAuth: true },
		},
		{ 	path: '/login',
			name: 'login',
			component: LoginUser
		},
		{ 	path: '/register',
			name: 'register',
			component: RegisterUser
		},
		{	path: '/forgot-password',
			name: 'forgotPassword',
			component: ForgotPassword
		},
		// if url does not exist, go to homepage
		{
			path: '/',
			redirect: { name: "dashboard" }
		},
		// if url does not exist, go to homepage
		{
			path: '*',
			redirect: { name: "dashboard" }
		},
		{
			path: "/index.html",
			redirect: { name: "dashboard" }
		}
	]
});

router.beforeEach(async (to, from, next) => {
	if (to.name === 'dashboard' && to.query.deep) {
		const deepValue = to.query.deep
		console.log(deepValue)
		const season = await getSeasonByDeeplink(deepValue)
		if (season) {
			store.dispatch('setSeason', season)
			next()
		} else {
			next({
				path: '/login',
				query: { redirect: to.fullPath }
			})
		}
	} else if (to.matched.some(record => record.meta.requiresAuth)) {
		const user = await getUserFromLocalStorage()
		if (!user) {
			next({
				path: '/login',
				query: { redirect: to.fullPath }
			})
		} else {
			next()
		}
	} else {
		next()
	}
})

	async function getUserFromLocalStorage() {
		return new Promise(resolve => {
			const user = JSON.parse(localStorage.getItem('user'))
			resolve(user)
		})
	}

	async function getSeasonByDeeplink(deeplink) {
		try {
			const response = await axios.get(process.env.VUE_APP_API_URL + 'season/deeplink/' + deeplink);
			return response.data;
		} catch (error) {
			console.error('Error fetching season:', error);
			return null;
		}
	}

new Vue({
	store,
	router,
	// Automatic Login (when browser is refrehed)
	created() {
		try {
			// get the user from the local storage
			var userInLocalStorage = localStorage.getItem('user')
			if (userInLocalStorage) {
				// parse the User
				var parsedUser = JSON.parse(userInLocalStorage)
				// check if it is an Object
				if (parsedUser && typeof (parsedUser) === 'object') {
					// set the User to vuex state
					this.$store.commit('SET_USER_DATA', parsedUser)
					// get the last Unit used by the User from localstore
				}
			} else {
				localStorage.removeItem('user')
				//this.$router.push({ name: 'login' })
			}
		} catch (e) {
			localStorage.removeItem('user')
			console.log('deleted invalid User from Localstore!')
			//this.$router.push({ name: 'login' })
		}
	},
	render: h => h(App)
}).$mount('#app')
