<template>
  <div>

		<NavBar
      v-if="isPlayer"
      @gotoPyramid="gotoPyramid"
      @gotoRanking="gotoRanking"
      @gotoGames="gotoGames"
      @openUserConfig="openUserConfig"
      @showSeasons="showSeasons"
		/>

    <div v-if="showPyramid" :style="{'margin-top': !loggedIn ? '8em' : '6em'}">

      <div class="title" v-if="!loggedIn && season">
        <h1 class="text-left mb-0" style="font-size: 3.5vw;">{{ season.club }}</h1>
        <h2 style="font-size: 3vw;">{{ season.label }}</h2>
        <p style="font-size: 1.8vw;">{{ getDate(season.start, true) + ' bis ' + getDate(season.end, true) }}</p>
      </div>

      <img class="clublogo" v-if="!loggedIn" :src="require('@/assets/tc_horw.png')" @click="$store.dispatch('logout')" />

      <b-container id="pyramid" class="pyramid my-3" v-if="users">
        <b-row v-if="season">
          <b-col v-if="loggedIn" class="pyramid_title text-center">{{ season.label }}</b-col>
        </b-row>
        <b-row align-h="center" v-for="row in rowsInPyramid" :key="'row_'+row" no-gutters>
          <b-col 
            class="d-flex justify-content-center align-items-center px-0 py-0" 
            v-for="(user, userIndex) in getIndicesOfRow(row)" 
            :key="userIndex" 
            :cols="2"
          >
            <template v-if="user = getUserByRank(user)">
              <UserAvatar
                class="avatar"
                :user="user"
                :size="calculateSize()+(loggedIn ? 4 : 18) * (rowsInPyramid - row)"
                :active="user.id === newWinner"
                @click.native="isAdmin ? newGame(user.id) : openUserConfig(user)"
              ></UserAvatar>
            </template>
            <template v-else>
              <div 
                class="empty" 
                :style="{
                    'width': calculateSize()+(loggedIn ? 4 : 18)*(rowsInPyramid - row) + 'px', 
                    'height': calculateSize()+(loggedIn ? 4 : 18)*(rowsInPyramid - row) + 'px'}
                ">
                  <img src="avatars/circle_2.svg" style="opacity: 0.3" />
              </div>

            </template>
          </b-col>
        </b-row>
      </b-container>

      <b-container class="mb-3 text-center" style="max-width: 58em;">
          <div 
            v-for="(user, userIndex) in users" 
            :key="userIndex" 
            class="d-inline-block mx-2" 
            :style="{'display': user.rank > pyramidSize || noGamesPlayed ? 'block' : 'none !important'}
          ">
            <UserAvatar
              :user="user"
              :size="calculateSize() * 0.7"
              :active="user.id == newWinner"
              @click.native="isAdmin ? newGame(user.id) : openUserConfig(user)"
            />
          </div>
      </b-container>


      <div class="game_slider" v-if="!noGamesPlayed && showSlider && isPlayer">
        <p class="mb-0"><small>{{ getFullUsername(lastGame.winner) + ' s. ' + getFullUsername(lastGame.loser)  }}</small></p>
        <MyIcon size="s" color="blue" icon="circle-minus" iconStyle="solid" hovercolor="red" :clickable="true" @click.native="numberOfGamesToCalculate = numberOfGamesToCalculate == 1 ? 1 : numberOfGamesToCalculate - 1; calculateRanking()" />
        <b-form-input class="mx-2" v-if="games" v-model.number="numberOfGamesToCalculate" type="range" min="0" :max="games.length" @change="calculateRanking()"></b-form-input>
        <MyIcon size="s" color="blue" icon="circle-plus" iconStyle="solid" hovercolor="red" :clickable="true" @click.native="numberOfGamesToCalculate = numberOfGamesToCalculate == games.length ? games.length : numberOfGamesToCalculate + 1; calculateRanking()" /><br />
        <small>{{ numberOfGamesToCalculate + ' Partien' }}</small>
        <MyIcon icon="xmark" class="slider" @click.native="showSlider = false" />
      </div>

      <MyIcon v-if="isPlayer" icon="slider" class="slider" @click.native="showSlider = true" />
    </div>

    <b-container class="ranking mt-5" v-if="users && showRanking">
      <b-row class="striped-row">
        <b-col cols="1"></b-col>
        <b-col></b-col>
        <b-col style="text-align: center;" cols="1"><MyIcon icon="tennis-ball" /></b-col>
        <b-col style="text-align: center;" cols="1"><MyIcon icon="trophy" /></b-col>
        <b-col style="text-align: center;" cols="1"><MyIcon icon="face-sad-sweat" /></b-col>
        <b-col style="text-align: center;" cols="1"><MyIcon icon="wreath-laurel" /></b-col>
      </b-row>
      <b-row v-for="(user, userIndex) in users" :key="userIndex" :class="{'striped-row': userIndex % 2 !== 0}">
        <b-col style="text-align: right;" cols="1" class="text-right">{{ user.points > 0 ? user.rank : '' }}.</b-col>
        <b-col style="text-align: left;" :class="{ 'pointer': isAdmin }" @click="openUserConfig(user)">{{ user.firstname + ' ' + user.lastname }}</b-col>
        <b-col style="text-align: center;" cols="1">{{ user.games }}</b-col>
        <b-col style="text-align: center;" cols="1">{{ user.wins }}</b-col>
        <b-col style="text-align: center;" cols="1">{{ user.games - user.wins }}</b-col>
        <b-col style="text-align: center;" cols="1"><strong>{{ user.points }}</strong></b-col>
      </b-row>
    </b-container>

    <b-container class="ranking mt-5" v-if="users && showGames">
      <b-row class="striped-row">
        <b-col cols="3"><MyIcon v-if="isAdmin" icon="pencil" color="white" @click.native="showMatchAdmin = !showMatchAdmin" /></b-col>
        <b-col style="text-align: left;"><MyIcon icon="trophy" /></b-col>
        <b-col style="text-align: left;"><MyIcon icon="face-sad-sweat" /></b-col>
      </b-row>
      <b-row v-for="(game, gameIndex) in games" :key="gameIndex" :class="{'striped-row': gameIndex % 2 !== 0}">
        <b-col cols="3" style="text-align: left;"><small>{{ getDate(game.timestamp, false) }}</small></b-col>
        <b-col style="text-align: left;">{{ getShortUsername(game.winner) }}</b-col>
        <b-col style="text-align: left;">{{ getShortUsername(game.loser) }}</b-col>
        <b-col v-if="isAdmin && showMatchAdmin" cols="2">
          <MyIcon icon="rotate" iconStyle="thin" hovercolor="red" @click.native="swapGame(game.id)" title="Sieger und Verlierer tauschen" />&nbsp;
          <MyIcon v-if="isAdmin && showMatchAdmin" icon="trash" iconStyle="thin" hovercolor="red" @click.native="deleteGame(game.id)" />
        </b-col>
      </b-row>
    </b-container>

    <div class="bottomline text-center" v-if="!loggedIn && !noGamesPlayed">
      Das Pyramidenspiel für deinen Tennisclub? – <a href="https://www.pyramix.ch" target="_blank">www.pyramix.ch</a>
    </div>

    <div class="seasons_tab" v-if="showSeasonsTab">
      <div v-for="(season, seasonIndex) in $store.state.user.seasons" :key="seasonIndex" @click="setSeason(season)" :class="{'bold': season == $store.state.season}">{{ season.label }}</div>
      <div v-if="!showAccessCodeInput">
        <span class="link" @click="showAccessCodeInput = true">+ Neuer Pyramide beitreten</span>
      </div>
      <div v-else>
        <b-form @submit.prevent="joinNewPyramid">
          <b-form-input
            v-model="accessCode"
            placeholder="Zugangscode eingeben"
          ></b-form-input>
          <b-button type="submit" variant="success">beitreten</b-button>
          <b-button type="button" variant="secondary" @click="showAccessCodeInput = false">Abbrechen</b-button>
        </b-form>
      </div>
    </div>

		<!-- dialog window to config User -->
		<UserConfig
			v-if="userToConfig"
			:userID="userToConfig.id"
			@userConfigDone="userConfigDone"
		></UserConfig>

  </div>
</template>

<script>
// import VUEX getters
import { authComputed } from '../vuex/helpers.js'

import axios from 'axios'

// Components
import UserConfig from '../modals/UserConfig.vue'

// Subcomponents
import UserAvatar from '../components/Avatar.vue'
import NavBar from '../components/NavBar.vue'

// import additional layout
import MyIcon from '../components/Icon.vue'


export default {
  name: 'DashBoard',
  components: {
    UserConfig,
    UserAvatar,
    NavBar,
    MyIcon
  },
  computed: {
    ...authComputed,
    gamesToCalculate() {
      if (this.numberOfGamesToCalculate == null) return this.games
      return this.games.slice(0, this.numberOfGamesToCalculate)
    },
    lastGame() {
      if (!this.games) return false
      if (!this.numberOfGamesToCalculate > 0) return false
      return this.games[this.numberOfGamesToCalculate - 1]
    },
    pyramidSize() {
      let sum = 0
      for (let i = 1; i <= this.rowsInPyramid; i++) {
        sum += i
      }
      return sum
    }
  },
  props: ['seasonID'],
  data: () => ({
    // options
    season: null,
    maxGames: null,      // max games of the same opponents
    rowsInPyramid: null,  // number of players in pyramid

    // new match
    newWinner: null,
    newLoser: null,

    showRanking: false,
    showGames: false,
    showPyramid: true,
    showSlider: false,
    showMatchAdmin: false,
    showSeasonsTab: false,
    showAccessCodeInput: false,
    accessCode: '',
		userToConfig: false,

    // variables
    noGamesPlayed: false,
    noPlayersInSeason: false,
    numberOfGamesToCalculate: 0,
    users: [],
    games: [],

  }),
  created() {
    this.getSeason()
  },
  watch: {
    season(newSeason, oldSeason) {
      if (newSeason && newSeason.id !== (oldSeason && oldSeason.id)) {
        this.getUsers();
        this.getGames();
      }
    },
  },  
  methods: {
    setSeason(season) {
      this.$store.dispatch('setSeason', season)
      this.showSeasonsTab = false
      this.getSeason(season.id)
    },
    joinNewPyramid() {
      axios.post(process.env.VUE_APP_API_URL + 'user/join', { accesscode: this.accessCode })
        .then(response => {
          this.showSeasonsTab = false;
          this.season = response.data
          const newSeason = response.data;
          // Füge die neue Season zum Store hinzu
          return this.$store.dispatch('addUserSeason', newSeason).then(() => newSeason);
        })
        .then(() => {
          this.$toast.success('Erfolgreich der neuen Pyramide beigetreten.', { duration: 5000 });
          this.showAccessCodeInput = false;
          this.accessCode = '';
        })
        .catch(() => {
          this.$toast.error('Fehler beim Beitreten der neuen Pyramide. Bitte Zugangscode überprüfen.', { duration: 5000 });
        });
    },
    getSeason(seasonID) {
      if (!seasonID) seasonID = this.$store.state.season.id
      axios.get(process.env.VUE_APP_API_URL + 'season/' + seasonID)
        .then(response => {
          this.season = response.data
          this.maxGames = response.data.maxgames
          this.rowsInPyramid = response.data.pyramidsize
        })
        .catch(() => {
          this.$toast.error('Die Saison konnte nicht geladen werden. Bitte Internetverbindung prüfen.', { duration: 5000 });
        })
    },
    getUsers(seasonID) {
      if (!seasonID) seasonID = this.$store.state.season.id
      axios.get(process.env.VUE_APP_API_URL + 'user/season/' + this.season.id)
        .then(response => {
          if (response.status == 204) {
            this.users = []
            this.noPlayersInSeason = true
          } else {
            this.users = response.data
            this.getGames();
          }
        })
    },
    getGames() {
      axios.get(process.env.VUE_APP_API_URL + 'game/season/' + this.season.id)
        .then(response => {
          console.log('Received games data:', response.data)
          if (response.status == 204) {
            console.log(this.$store.state.season)
            this.noGamesPlayed = true
            this.games = []
          } else {
            //console.log(this.$store.state)
            this.games = response.data
            this.numberOfGamesToCalculate = this.games.length
            this.noGamesPlayed = false
            this.calculateRanking();
          }
        })
        .catch(() => {
        })
    },
    calculateRanking() {
      if (!this.season) return false
      const rankings = {}
      this.users.forEach(user => {
        rankings[user.id] = {
          rank: null,
          points: 0,
          games: 0,
          wins: 0
        }
      })

      this.gamesToCalculate.forEach(game => {

        const loserRank = rankings[game.loser].rank
        const winnerRank = rankings[game.winner].rank
        const loserRow = this.getRowOfNumber(loserRank)
        const winnerRow = this.getRowOfNumber(winnerRank)
        const loser = this.users.filter(user => user.id == game.loser)[0]
        const loserName = loser.firstname + ' ' + loser.lastname
        const winner = this.users.filter(user => user.id == game.winner)[0]
        const winnerName = winner.firstname + ' ' + winner.lastname
        
        // 2. Get the pyramid points
        /*
        let pyramidPoints = 0
        if (loserRank === null) { pyramidPoints = 0 }
        else if (loserRank > this.pyramidSize) { pyramidPoints = 0 }
        else { pyramidPoints = this.rowsInPyramid - this.getRowOfNumber(loserRank) + 1}
        */
       let pyramidPoints = Math.min(winnerRow, this.rowsInPyramid) - loserRow
       if (pyramidPoints < 0) pyramidPoints = 0
       
       // 3. is there free space in the pyramid?
       let playersWithGames = Object.values(rankings).filter(r => r.games > 0).length
       let freeSpaceInPyramid = playersWithGames < this.pyramidSize
       console.log('Es sind ' + playersWithGames + ' Spieler in der Pyramide mit ' + this.pyramidSize + ' Plätzen.')
       
       // 4. Update number of games and wins
       rankings[game.loser].games++
       
       // 5. add points for the loser (if winner is in same or higher row only)
       if (rankings[game.loser].points < 5) {
         rankings[game.loser].points += this.season.loserpoints
         console.log('Der Verlierer erhält zum Trost +' + this.season.loserpoints)
       }
        
        // 6. Update ranks for the loser
        let loserNewInPyramid = false
        // check if the loser has already a rank in the pyramid
        if (rankings[game.loser].rank === null) {
          loserNewInPyramid = true
          console.log(loserName + ' ist neu.')
          // set him to rank 15 or lower, if more than 15 Players have points
          rankings[game.loser].rank = freeSpaceInPyramid ? this.pyramidSize : playersWithGames + 1
          console.log('Sein Rang ist ' + rankings[game.loser].rank)
        } else {
          console.log(loserName + ' liegt auf Rang ' + rankings[game.loser].rank)
        }
        const oldLoserRanking = rankings[game.loser].rank
        Object.entries(rankings).forEach(([key, ranking]) => {
          // skip the loser
          if (key == game.loser) return
          // skip opponents that do not have a rank
          if (ranking.rank === null) return
          // if an opponent has less points
          if (ranking.points < rankings[game.loser].points) {
            // check if his rank is lower although he has less points => switch ranks
            if (ranking.rank <= oldLoserRanking) {
              if (!loserNewInPyramid || !freeSpaceInPyramid) ranking.rank++
              rankings[game.loser].rank--
              console.log(loserName + ': Rang wird auf ' + rankings[game.loser].rank + ' korrigiert.')
            } 
          } else {
            // push the opponent one rank up in the pyramid
            if (loserNewInPyramid && freeSpaceInPyramid) ranking.rank--
          }
        })
        
        // 7. is there still free space in the pyramid?
        playersWithGames = Object.values(rankings).filter(r => r.games > 0).length;
        freeSpaceInPyramid = playersWithGames < this.pyramidSize
        console.log('Es sind ' + playersWithGames + ' Spieler in der Pyramide mit ' + this.pyramidSize + ' Plätzen.')
        
        // 8. Update number of games and wins
        rankings[game.winner].games++
        rankings[game.winner].wins++

        // 9. add points for the winner
        rankings[game.winner].points += this.season.winnerpoints - (this.season.rowdiscount == 1 ? this.rowsInPyramid + 1 - winnerRow : 0) + pyramidPoints
        
        // 10. Update ranks for the winner
        let winnerNewInPyramid = false
        // check if the winner has already a rank in the pyramid
        if (rankings[game.winner].rank === null) {
          winnerNewInPyramid = true
          console.log(winnerName + ' ist neu.')
          rankings[game.winner].rank = freeSpaceInPyramid ? this.pyramidSize : playersWithGames + 1
          console.log('Sein Rang ist ' + rankings[game.winner].rank)
        } else {
          console.log(winnerName + ' liegt auf Rang ' + rankings[game.winner].rank)
        }
        const oldWinnerRanking = rankings[game.winner].rank
        Object.entries(rankings).forEach(([key, ranking]) => {
          // skip the winner
          if (key == game.winner) return
          // skip opponents that do not have a rank
          if (ranking.rank === null) return
          // if an opponent has less points
          if (ranking.points < rankings[game.winner].points) {
            if (ranking.rank <= oldWinnerRanking) {
              if (!winnerNewInPyramid || !freeSpaceInPyramid) ranking.rank++
              rankings[game.winner].rank--
              console.log(winnerName + ': Rang wird auf ' + rankings[game.winner].rank + ' korrigiert.')
            }
          } else {
            // push the opponent one rank up in the pyramid
            if (winnerNewInPyramid && freeSpaceInPyramid) ranking.rank--
          }
        })

      })

      let playersWithGames = 0;
        Object.values(rankings).forEach(ranking => {
          if (ranking.points > 0) playersWithGames++
        })


      // assign rankings to user-Objects
      this.users.forEach(user => {
        this.$set(user, 'points', rankings[user.id].points)
        this.$set(user, 'rank', rankings[user.id].rank !== null ? rankings[user.id].rank : Math.max((this.pyramidSize + 1), playersWithGames + 1))
        this.$set(user, 'games', rankings[user.id].games)
        this.$set(user, 'wins', rankings[user.id].wins)
      })

      this.users.sort((a, b) => {
        if (a.rank === null && b.rank === null) {
          // Wenn beide Ränge null sind, sortiere alphabetisch nach Vorname
          return a.lastname.localeCompare(b.lastname)
        } else if (a.rank === null) {
          // Wenn der Rang von a null ist, b ist besser (kleinerer Rang)
          return 1
        } else if (b.rank === null) {
          // Wenn der Rang von b null ist, a ist besser (kleinerer Rang)
          return -1
        } else {
          // Andernfalls sortiere nach Rang (aufsteigend)
          return a.rank - b.rank
        }
      })
    },
    newGame(userID) {
      console.log(userID)
      if (!this.isAdmin) return false
      if (this.newWinner === null) {
        this.newWinner = userID
      } else {
        if (this.newWinner == userID) {
          this.newWinner = null
          this.newLoser = null
          return false
        }
        this.newLoser = userID
        let winner = this.getFullUsername(this.newWinner)
        let loser = this.getFullUsername(this.newLoser)

        this.$bvModal.msgBoxConfirm('Sieg von ' + winner + ' gegen ' + loser + '?', {
          title: 'Resultat eintragen',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'eintragen',
          cancelTitle: 'abbrechen',
          centered: true
        })
        .then(confirmed => {
          if (confirmed) {
            axios.post(process.env.VUE_APP_API_URL + 'game/' + this.season.id, { winner: this.newWinner, loser: this.newLoser })
            .then(() => {
              this.getGames();
              this.$toast.success('Sieg von ' + winner + ' gegen ' + loser + ' gespeichert.', { timeout: 2000 })
              this.newWinner = null
              this.newLoser = null
              this.noGamesPlayed = false
            })
            .catch(error => {
              this.$toast.error(error.response.data, { duration: 5000 });
              this.newWinner = null
              this.newLoser = null
            })
          } else {
            this.newWinner = null
            this.newLoser = null
          }
        })
      }
    },
    swapGame(gameID) {
      if (!this.isAdmin) return false
      axios.put(process.env.VUE_APP_API_URL + 'game/' + gameID + '/swap')
      .then(() => { 
        this.$toast.success('Spiel gedreht.', { timeout: 2000 });
        this.getGames()
      })
      .catch(() => { 
        this.$toast.error('Spiel konnte nicht gedreht werden.', { duration: 5000 });
      })
    },
    deleteGame(gameID) {
      if (!this.isAdmin) return false
      this.$bvModal.msgBoxConfirm('Dieses Spiel löschen?', {
        title: 'Spiel löschen',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'löschen',
        cancelTitle: 'abbrechen',
        centered: true
      })
      .then(confirmed => {
        if (confirmed) {
          axios.delete(process.env.VUE_APP_API_URL + 'game/' + gameID)
          .then(() => { 
            this.$toast.success('Spiel gelöscht.', { timeout: 2000 });
            this.getGames()
          })
          .catch(() => { 
            this.$toast.error('Spiel konnte nicht gelöscht werden.', { duration: 5000 });
          })
        }
      })
    },
    getShortUsername(userID) {
      if (this.users.length == 0) return ''
      let found = this.users.filter(user => user.id === userID)
      if (found.length > 0) return found[0].firstname + ' ' + found[0].lastname[0] + '.'
      return ''
    },
    getFullUsername(userID) {
      if (this.users.length == 0) return ''
      let found = this.users.filter(user => user.id === userID)
      if (found.length > 0) return found[0].firstname + ' ' + found[0].lastname
      return ''
    },
    getDate(date, long) {
      const mydate = new Date(date);
      const options = { year: 'numeric', month: long ? 'long' : '2-digit', day: '2-digit' };
      const formatter = new Intl.DateTimeFormat('de-DE', options);
      return formatter.format(mydate);
    },
    getTime(date) {
      const mydate = new Date(date);
      const options = { hour: 'numeric', minute: 'numeric' };
      const formatter = new Intl.DateTimeFormat('de-DE', options);
      return formatter.format(mydate);
    },
    getRowOfNumber(number) {
      if (number === null) return this.rowsInPyramid + 1
      let currentRow = 1
      let currentNumber = 1
      while (currentNumber < number) {
        currentRow++;
        currentNumber += currentRow;
      }
      return Math.min(this.rowsInPyramid + 1, currentRow)
    },
    getUserByRank(rank) {
      return this.users.find(user => user.rank === rank) || null;
    },
    getIndicesOfRow(row) {
      let indices = []
      if (row > this.rowsInPyramid) { 
        return false
      }
      for (let i = 1; i <= this.pyramidSize; i++) {
        if (this.getRowOfNumber(i) == row) indices.push(i)
      }
//      console.log('Reihe ' + row + ':')
//      console.log(indices)
      return indices
    },
    showSeasons() {
      this.showSeasonsTab = !this.showSeasonsTab
    },
    gotoPyramid() { 
      this.showPyramid = true
      this.showGames = false
      this.showRanking = false
    },
    gotoRanking() { 
      this.showRanking = true
      this.showPyramid = false
      this.showGames = false
    },
    gotoGames() { 
      this.showGames = true
      this.showPyramid = false
      this.showRanking = false
    },

    openUserConfig(user) {
      if (!this.isAdmin && this.$store.state.user.id !== user.id) return false
			this.userToConfig = user
			// Show the modal
			this.$nextTick(() => {
				this.$bvModal.show('user_config')
			})
		},
		userConfigDone() {
      this.userToConfig = false
      this.getUsers()
		},

    calculateSize() {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const smaller = Math.min(screenHeight * 0.72, screenWidth);
      return Math.min(90, Math.floor(smaller * 0.15));
    }

  }
}
</script>

<style scoped>
.pyramid {
  max-width: 60em;
}
.avatar {
  position: relative;
}
.empty {
  position: relative;
  padding: 0px 5px 10px;
}
.game_slider {
  text-align: center;
  position: fixed;
  bottom: 0em;
  padding-bottom: 0.8em;
  width: 100%;
  background-color: rgba(255,255,255, 0.95);
  z-index: 100;
}
.ranking {
  font-size: 0.9em;
  width: 95%;
}
.striped-row {
  background-color: rgba(0,0,0,0.1);
}
.pointer {
  cursor: pointer;
}

.seasons_tab {
  border-radius: 0.24em;
  position: fixed;
  top: 40%;
  left: 50%;
  width: 20em;
  margin-left: -10em;
  border: 1px black solid;
  font-size: 0.8em;
  padding: 1em 1em 1em;
  cursor: pointer;
  background-color: white;
  z-index: 9999;
}

.seasons_tab div {
  padding: 0.2em;
  border-radius: 0.24em;
  margin: 0.2em 0
}

.seasons_tab div:hover {
  background-color: rgba(255,0,0,0.2);
}

.slider {
  position: fixed;
  bottom: 0.2em;
  right: 0.7em;
}

.title {
  font-size: 2em;
  position: fixed;
  left: 2em;
  top: 2em;
}

.clublogo {
  height: 13vw;
  position: fixed;
  right: 4em;
  top: 4em;
}

.bottomline {
  position: fixed;
  bottom: 0;
  background-color: #e9f0f5;
  width: 100%;
  font-size: 1em;
  padding: 0.25em 0 0.1em;
  z-index: 999;
  color: #666;
}

.pyramid_title {
  font-size: 1em;
  font-weight: 500;
  padding-bottom: 0.5em;
}

.bold {
  background-color: rgba(255, 0, 0, 0.3);
}
</style>