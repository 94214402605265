// I use this file to import Vuex helpers, specifically mapGetters
// i.e. make the getter loggedIn accessible to any component that needs it.

// https://www.educative.io/answers/how-to-use-getters-in-vuex

import { mapGetters } from 'vuex'

// export authComputed, which uses the mapGetters helper to map to the loggedIn getter in store.js file
export const authComputed  = {
	...mapGetters(['loggedIn', 'isPlayer', 'isAdmin'])
}