<!--
Navbar
======

Fixed navbar on top of the Application. Always visible.

-->
<template>
	<b-navbar fixed="top" toggleable="sm">

		<!-- Logo -->
		<NavIcon 
			icon="ball-pile" 
			iconStyle="solid" 
			label="Pyramide" 
			@click.native="$emit('gotoPyramid')"
		/>

		<NavIcon
			icon="list"
			:clickable="true"
			label="Rangliste"
			@click.native="$emit('gotoRanking')"
		></NavIcon>

		<NavIcon
			icon="tennis-ball"
			:clickable="true"
			label="Matches"
			@click.native="$emit('gotoGames')"
		></NavIcon>

		<NavIcon
			icon="calendar"
			:clickable="true"
			label="Saisons"
			@click.native="$emit('showSeasons')"
		></NavIcon>

		<NavIcon
			icon="user"
			:clickable="true"
			label="Profil"
			@click.native="openUserConfig()"
		></NavIcon>

		<NavIcon
			v-if="loggedIn"
			onclick="logout"
			icon="door-closed"
			label="Logout"
		></NavIcon>

		<NavIcon
			v-if="!loggedIn"
			icon="door-open"
			label="Login"
			@click.native="$router.push({name: 'login'})"
		></NavIcon>

	</b-navbar>
</template>

<script>
// import VUEX getters
import { authComputed } from '../vuex/helpers.js'

// import additional layout
import NavIcon from './NavIcon.vue'

export default {
	name: 'NavBar',
	components: {
		NavIcon,
	},
	props: {
	},
	computed: {
		...authComputed
	},
	data: () => ({
		userToConfig: false,
	}),
	methods: {
		openUserConfig() {
			this.$emit('openUserConfig', this.$store.state.user)
		},
	}
}
</script>

<style lang="css" scoped>
	.navbar {
		background-color: white;
		border-bottom: 0.5px solid #ccc;
		box-shadow: 0 .4375rem .5625rem -.4375rem #d6dee6;
		padding: 0.3em 0;
	}
	.avatar {
		margin: 0 1.6em;
	}

</style>