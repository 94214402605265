<!--
NavIcon
=======

Displays an Icon with a Label beneath and points to a specific part of the app or a link.
Useful in the NavBar

-->
<template>

	<component
		class="nav_icon"
		:class="{ hover_icon: link || onclick }"
		:is="link ? 'router-link' : 'div'"
		:to="link ? '/' + link : false"
	>
		<Icon
			class="icon"
			:size="size"
			:color="color"
			:icon="icon"
			:title="title"
			:hover="link || onclick ? true : false"
			:iconStyle="iconStyle"
			:clickable="link || clickable ? true : false"
			@click.native="onclick ? call_from_store(onclick) : false"
		/>
		<p
			v-if="label"
			v-html="label"
			class="label"
			@click="onclick ? call_from_store(onclick) : false"
			:style="{fontSize: labelSize + 'em', 'color': iconColor }"
		></p>
	</component>
</template>

<script>
import Icon from '../components/Icon.vue'

export default {
	name: 'NavIcon',
	components: {
		Icon
	},
	props: {
		link: { required: false, default: false },
		icon: { type: String, required: true },
		label: { required: false },
		title: { type: String, required: false },
		onclick: { type: String, required: false, default: '' },	// needed for logout
		clickable: { type: Boolean, required: false, default: false },	// needed for logout
		color: { type: String, required: false, default: null },
		iconStyle: { type: String, required: false, default: 'light' },
		size: { type: String, required: false, default: 'l' },
		labelSize: { type: String, required: false, default: '0.7' }
	},
	computed: {
		iconColor() {
			if (this.color !== null) return this.color
			return this.darkMode ? 'white' : '#333'
		}
	},
	methods: {
		// NavIcon can directly call actions in the store (i.e. Logout)
		call_from_store(action) {
			this.$store.dispatch(action)
		},
	}
}
</script>



<style lang="css" scoped>
	.nav_icon {
		list-style-type: none;
		width: 6em;
		text-align: center;
		padding: 0;
		text-decoration: none;
		cursor:pointer;
	}
	.nav_icon .icon {
		color: #333;
		text-align: center;
	}
	.hover_icon:hover .icon {
		color: #3d74ca;
		cursor: pointer;
	}
	.hover_icon:hover .label {
		color: #3d74ca;
		cursor: pointer;
	}
	.label {
		margin: 0;
	}

	@media screen and (max-width: 575px) {
		.nav_icon {
			width: auto;
			margin-right: 0.6em;
			margin-left: 0.6em;
		}
	}

</style>