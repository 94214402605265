<template>
  <div>
    <b-card
      :img-src="require('@/assets/titleimg.jpg')"
      img-alt="Image"
      img-top
      class="forgot-password-card"
    >
      <b-form @submit.prevent="resetPassword">
        <!-- E-Mail -->
        <b-form-group>
          <label for="email">E-Mail</label>
          <b-form-input
            v-model="email"
            :state="valid_email ? true : (buttonPressed ? false : null)"
            id="email"
            name="email"
            type="email"
          ></b-form-input>
          <b-form-invalid-feedback>
            Bitte geben Sie eine gültige E-Mail-Adresse ein.
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Reset-Button -->
        <b-button class="mt-2 mb-4" variant="success" @click="resetPassword">Passwort per Mail senden</b-button>

        <p class="mb-0">
          <small>Zurück zum <span class="link" @click="$router.push({name: 'login'})">Login</span></small>
        </p>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: '',
      buttonPressed: false
    }
  },
  computed: {
    valid_email() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(this.email);
    }
  },
  methods: {
    resetPassword() {
      this.buttonPressed = true;
      if (this.valid_email) {
        // API-Aufruf zum Zurücksetzen des Passworts (dummy example)
        axios.post(process.env.VUE_APP_API_URL + 'user/forgotpassword', { email: this.email })
          .then(() => {
            this.$toast.success('Passwort-Reset-Anweisungen wurden an Ihre E-Mail-Adresse gesendet.', { duration: 5000 });
            this.$router.push({ name: 'login' });
          })
          .catch(() => {
            this.$toast.error('Kein Benutzer unter dieser Mailadresse registriert. Bitte versuchen Sie es erneut.', { duration: 5000 });
          });
      }
    }
  }
}
</script>

<style scoped>
.forgot-password-card {
  max-width: 400px;
  margin: 0 auto;
}
.link {
  color: #117eae;
  text-decoration: underline;
  cursor: pointer;
}
</style>
